<template>
  <div class="row-fluid mt-60 csn-user">
    <div v-if="rendersAccountMenu" class="col-md-2">
      <UserAccountMenuList />
    </div>

    <div v-else class="csn-user-return">
      <span class="csn-user-return-text">
        {{ t('go_to_section') }}
      </span>

      <button @click="goBackToSectionMenu" class="csn-user-return-arrow-back">
        <SlideArrow />
      </button>
    </div>

    <div class="col-md-10 user-account-content">
      <div>
        <router-view></router-view>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import {
  USER,
  Module,
  TOGGLE_MY_ACCOUNT_SIDE_MENU,
  SCREEN_WIDTH,
  Digit,
  TOGGLE_MAIN_MENU,
  SHOWS_MAIN_MENU
} from '@/constants'

export default {
  name: USER,
  components: {
    UserAccountMenuList: () => import('./components/UserAccountMenuList'),
    SlideArrow: () => import('@/components/svg/SlideArrow'),
  },
  computed: {
    ...mapState(Module.DEVICE, [SCREEN_WIDTH]),
    ...mapState(Module.MAIN, [SHOWS_MAIN_MENU]),

    t() {
      return this.$createComponentTranslator(USER)
    },
    rendersAccountMenu() {
      return this.SCREEN_WIDTH >= Digit.ONE_THOUSAND_TWO_HUNDRED
    },
  },
  methods: {
    ...mapMutations(Module.MAIN, [
      TOGGLE_MY_ACCOUNT_SIDE_MENU,
      TOGGLE_MAIN_MENU,
    ]),
    goBackToSectionMenu() {
      this.SHOWS_MAIN_MENU && this.TOGGLE_MAIN_MENU()
      this.TOGGLE_MY_ACCOUNT_SIDE_MENU(true)
    },
  },
}
</script>
